.App {
  text-align: center;
  padding: 20px;
  background-color: #030513; /* 포스터의 배경색과 어울리는 어두운 배경색 */
  color: #fff; /* 흰색 글씨 */
}

.App-header {
  margin-bottom: 20px;
}

.poster {
  width: 100%;
  max-width: 600px;
  height: auto;
  /* border: 2px solid #fff;  포스터 테두리 삭제 */
}

.bands {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.band {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #333; /* 어두운 배경 */
  border: 1px solid #555; /* 약간 밝은 테두리 */
  border-radius: 5px;
  transition: background-color 0.3s;
  flex: 1 1 calc(33.333% - 40px);
  box-sizing: border-box;
  max-width: calc(33.333% - 40px);
  color: #fff; /* 흰색 글씨 */
}

.band:hover {
  background-color: #555; /* 밝은 회색으로 변경 */
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.set-list, .session-list {
  flex: 1;
  padding: 20px;
}

.set-list ul, .session-list ul {
  list-style-type: none;
  padding: 0;
}

.set-list li, .session-list li {
  margin-bottom: 5px;
}

.vertical-line {
  width: 2px;
  background-color: #ffffff;
  height: auto;
  margin: 0 20px;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .band {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .content {
    flex-direction: column;
    align-items: center;
  }

  .vertical-line {
    width: 100%;
    height: 2px;
    margin: 20px 0;
  }

  .set-list, .session-list {
    text-align: center;
    padding: 0;
  }
}

@media (max-width: 480px) {
  .band {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
